post a:hover {
    border-bottom-color: transparent;
    color: #6A4033 !important;
}

    post a:hover:before {
        color: #6A4033 !important;
    }

/* Mini Post */

.mini-post {
    display: -moz-flex;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-direction: column-reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    background: #ffffff;
    border: solid 1px rgba(160, 160, 160, 0.3);
    margin: 0 0 2em 0;
}

    .mini-post .image {
        overflow: hidden;
        width: 100%;
    }

        .mini-post .image img {
            -moz-transition: -moz-transform 0.2s ease-out;
            -webkit-transition: -webkit-transform 0.2s ease-out;
            -ms-transition: -ms-transform 0.2s ease-out;
            transition: transform 0.2s ease-out;
            width: 100%;
        }

        .mini-post .image:hover img {
            -moz-transform: scale(1.05);
            -webkit-transform: scale(1.05);
            -ms-transform: scale(1.05);
            transform: scale(1.05);
        }

    .mini-post div {
        padding: 1.25em 4.25em 0.1em 1.25em ;
        min-height: 4em;
        position: relative;
        -moz-flex-grow: 1;
        -webkit-flex-grow: 1;
        -ms-flex-grow: 1;
        flex-grow: 1;
    }			

    .mini-post header {
        padding: 1.25em 4.25em 0.1em 1.25em ;
        min-height: 4em;
        position: relative;
        -moz-flex-grow: 1;
        -webkit-flex-grow: 1;
        -ms-flex-grow: 1;
        flex-grow: 1;
    }

        .mini-post header h3 {
            font-size: 0.7em;
        }

        .mini-post header .published {
            display: block;
            font-family: "Raleway", Helvetica, sans-serif;
            font-size: 0.6em;
            font-weight: 400;
            letter-spacing: 0.25em;
            margin: -0.625em 0 1.7em 0;
            text-transform: uppercase;
        }

        .mini-post header .author {
            position: absolute;
            right: 2em;
            top: 2em;
        }

.mini-posts {
    margin: 0 0 2em 0;
}

    @media screen and (max-width: 1280px) {

        .mini-posts {
            display: -moz-flex;
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;
            -moz-flex-wrap: wrap;
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            width: calc(100% + 2em);
        }

            .mini-posts > * {
                margin: 2em 2em 0 0;
                width: calc(50% - 2em);
            }

            .mini-posts > :nth-child(-n + 2) {
                margin-top: 0;
            }

    }

    @media screen and (max-width: 480px) {

        .mini-posts {
            display: block;
            width: 100%;
        }

            .mini-posts > * {
                margin: 0 0 2em 0;
                width: 100%;
            }

    }

/* Post */

.post {
    padding: 3em 3em 1em 3em ;
    background: #ffffff;
    /* background: #e6e2dd; */
    border: solid 1px rgba(160, 160, 160, 0.3);
    margin: 0 0 3em 0;
    position: relative;
}

    .post > header {
        display: -moz-flex;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        border-bottom: solid 1px rgba(160, 160, 160, 0.3);
        left: -3em;
        margin: -3em 0 3em 0;
        position: relative;
        width: calc(100% + 6em);
    }

        .post > header .title {
            -moz-flex-grow: 1;
            -webkit-flex-grow: 1;
            -ms-flex-grow: 1;
            flex-grow: 1;
            -ms-flex: 1;
            padding: 3.75em 3em 3.3em 3em;
        }

            .post > header .title h2 {
                font-weight: 900;
                font-size: 1.5em;
                color: #73716E;
            }

            .post > header .title > :last-child {
                margin-bottom: 0;
            }

        .post > header .meta {
            padding: 3.75em 3em 1.75em 3em ;
            border-left: solid 1px rgba(160, 160, 160, 0.3);
            min-width: 17em;
            text-align: right;
            width: 17em;
        }

            .post > header .meta > * {
                margin: 0 0 1em 0;
            }

            .post > header .meta > :last-child {
                margin-bottom: 0;
            }

            .post > header .meta .published {
                color: #3c3b3b;
                display: block;
                font-family: "Raleway", Helvetica, sans-serif;
                font-size: 0.7em;
                font-weight: 800;
                letter-spacing: 0.25em;
                margin-top: 0.5em;
                text-transform: uppercase;
                white-space: nowrap;
            }

    .post > a.image.featured {
        overflow: hidden;
    }

        .post > a.image.featured img {
            -moz-transition: -moz-transform 0.2s ease-out;
            -webkit-transition: -webkit-transform 0.2s ease-out;
            -ms-transition: -ms-transform 0.2s ease-out;
            transition: transform 0.2s ease-out;
        }

        .post > a.image.featured:hover img {
            -moz-transform: scale(1.05);
            -webkit-transform: scale(1.05);
            -ms-transform: scale(1.05);
            transform: scale(1.05);
        }

    .post > footer {
        display: -moz-flex;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        -moz-align-items: center;
        -webkit-align-items: center;
        -ms-align-items: center;
        align-items: center;
    }

        .post > footer .actions {
            -moz-flex-grow: 1;
            -webkit-flex-grow: 1;
            -ms-flex-grow: 1;
            flex-grow: 1;
        }

        .post > footer .stats {
            cursor: default;
            list-style: none;
            padding: 0;
        }

            .post > footer .stats li {
                border-left: solid 1px rgba(160, 160, 160, 0.3);
                display: inline-block;
                font-family: "Raleway", Helvetica, sans-serif;
                font-size: 0.6em;
                font-weight: 400;
                letter-spacing: 0.25em;
                line-height: 1;
                margin: 0 0 0 2em;
                padding: 0 0 0 2em;
                text-transform: uppercase;
            }

                .post > footer .stats li:first-child {
                    border-left: 0;
                    margin-left: 0;
                    padding-left: 0;
                }

                .post > footer .stats li .icon {
                    border-bottom: 0;
                }

                    .post > footer .stats li .icon:before {
                        color: rgba(160, 160, 160, 0.3);
                        margin-right: 0.75em;
                    }

    @media screen and (max-width: 980px) {

        .post {
            border-left: 0;
            border-right: 0;
            left: -3em;
            width: calc(100% + (3em * 2));
        }

            .post > header {
                -moz-flex-direction: column;
                -webkit-flex-direction: column;
                -ms-flex-direction: column;
                flex-direction: column;
                padding: 3.75em 3em 1.25em 3em ;
                border-left: 0;
            }

                .post > header .title {
                    -ms-flex: 0 1 auto;
                    margin: 0 0 2em 0;
                    padding: 0;
                    text-align: center;
                }

                .post > header .meta {
                    -moz-align-items: center;
                    -webkit-align-items: center;
                    -ms-align-items: center;
                    align-items: center;
                    display: -moz-flex;
                    display: -webkit-flex;
                    display: -ms-flex;
                    display: flex;
                    -moz-justify-content: center;
                    -webkit-justify-content: center;
                    -ms-justify-content: center;
                    justify-content: center;
                    border-left: 0;
                    margin: 0 0 2em 0;
                    padding-top: 0;
                    padding: 0;
                    text-align: left;
                    width: 100%;
                }

                    .post > header .meta > * {
                        border-left: solid 1px rgba(160, 160, 160, 0.3);
                        margin-left: 2em;
                        padding-left: 2em;
                    }

                    .post > header .meta > :first-child {
                        border-left: 0;
                        margin-left: 0;
                        padding-left: 0;
                    }

                    .post > header .meta .published {
                        margin-bottom: 0;
                        margin-top: 0;
                    }

                    .post > header .meta .author {
                        -moz-flex-direction: row-reverse;
                        -webkit-flex-direction: row-reverse;
                        -ms-flex-direction: row-reverse;
                        flex-direction: row-reverse;
                        margin-bottom: 0;
                    }

                        .post > header .meta .author .name {
                            margin: 0 0 0 1.5em;
                        }

                        .post > header .meta .author img {
                            width: 3.5em;
                        }

    }

    @media screen and (max-width: 736px) {

        .post {
            padding: 1.5em 1.5em 0.1em 1.5em ;
            left: -1.5em;
            margin: 0 0 2em 0;
            width: calc(100% + (1.5em * 2));
        }

            .post > header {
                padding: 3em 1.5em 0.5em 1.5em ;
                left: -1.5em;
                margin: -1.5em 0 1.5em 0;
                width: calc(100% + 3em);
            }

                .post > header .title h2 {
                    font-size: 1.1em;
                }

    }

    @media screen and (max-width: 480px) {

        .post > header .meta {
            -moz-align-items: center;
            -webkit-align-items: center;
            -ms-align-items: center;
            align-items: center;
            -moz-flex-direction: column;
            -webkit-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;
        }

            .post > header .meta > * {
                border-left: 0;
                margin: 1em 0 0 0;
                padding-left: 0;
            }

            .post > header .meta .author .name {
                display: none;
            }

        .post > .image.featured {
            margin-left: -1.5em;
            margin-top: calc(-1.5em - 1px);
            width: calc(100% + 3em);
        }

        .post > footer {
            -moz-align-items: -moz-stretch;
            -webkit-align-items: -webkit-stretch;
            -ms-align-items: -ms-stretch;
            align-items: stretch;
            -moz-flex-direction: column-reverse;
            -webkit-flex-direction: column-reverse;
            -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
        }

            .post > footer .stats {
                text-align: center;
            }

                .post > footer .stats li {
                    margin: 0 0 0 1.25em;
                    padding: 0 0 0 1.25em;
                }

    }    