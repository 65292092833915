/* Intro */

#intro .logo {
    border-bottom: 0;
    display: inline-block;
    margin: 0 0 1em 0;
    overflow: hidden;
    position: relative;
    width: 4em;
}

    #intro .logo:before {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100px' height='100px' viewBox='0 0 100 100' preserveAspectRatio='none' zoomAndPan='disable'%3E%3Cpolygon points='0,0 100,0 100,25 50,0 0,25' style='fill:%23d48166' /%3E%3Cpolygon points='0,100 100,100 100,75 50,100 0,75' style='fill:%23d48166' /%3E%3C/svg%3E");
        background-position: top left;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        content: '';
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    #intro .logo img {
        display: block;
        margin-left: -0.25em;
        width: 4.5em;
    }

#intro header h2 {
    font-size: 2em;
    font-weight: 900;
    color: #6A4033;
}	

#intro header p {
    font-size: 0.8em;
}

@media screen and (max-width: 1280px) {

    #intro {
        margin: 0 0 3em 0;
        text-align: center;
    }

        #intro header h2 {
            font-size: 2em;
        }

        #intro header p {
            font-size: 0.7em;
        }

}

@media screen and (max-width: 736px) {

    #intro {
        margin: 0 0 1.5em 0;
        padding: 1.25em 0;
    }

        #intro > :last-child {
            margin-bottom: 0;
        }

        #intro .logo {
            margin: 0 0 0.5em 0;
        }

        #intro header h2 {
            font-size: 1.25em;
        }

        #intro header > :last-child {
            margin-bottom: 0;
        }

}