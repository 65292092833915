.userimage {  
    font-family: "Raleway", Helvetica, sans-serif;
    font-size: 9px;
    color: rgb(115, 113, 110);    
    
    .nameNormal {
        font-weight: 400;
        text-transform: uppercase;       
        display: block; 
        letter-spacing: 0.25em;
        white-space: nowrap;
        border-bottom: dotted 1px rgba(160, 160, 160, 0.65);
    }

    .nameExpanded {
        font-weight: 500;
        text-transform: uppercase;       
        display: block; 
        letter-spacing: 0.4em;
        white-space: nowrap;
    }

    .nameCompact {
        font-weight: 400;
        text-transform: uppercase;                
        letter-spacing: 0.25em;
        white-space: nowrap;
        padding: 0 0 20px 5px; 
        vertical-align: middle;
        border-bottom: dotted 1px rgba(160, 160, 160, 0.65);
    }    

    a {
        border: none;
    }

    img {
        border-radius: 100%;
    }

    &:hover {
        .name {
            border-bottom-color: transparent;
        }
    }
}

.userimagetext {
    font-family: "Raleway", Helvetica, sans-serif;
    font-size: 9px;
    color: rgb(115, 113, 110);   
    letter-spacing: 0.1em;
}