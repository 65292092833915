.TypeAheadDropDown {
    background: rgba(160, 160, 160, 0.075);
    border: solid 1px rgba(160, 160, 160, 0.3);
}

.TypeAheadDropDown ul::before {
    content: '';
}

.TypeAheadDropDown ul {
    list-style-type: none;
    text-align: left;
    margin: 0;
    padding: 0;
    border-top: 1px solid gray;
}

.TypeAheadDropDown li {
    padding: 10px 5px;
    cursor: pointer;
}

.TypeAheadDropDown li:hover {
    background: lightgray;
    /* text-decoration: underline; */
}

.TypeAheadDropDown img {
    width: 50px;
}