.progress-bar {
  width: 100%;
  height: 20px;
  background-color: #f0f0f0;
  border-radius: 10px;
  margin: 10px 0;
  overflow: hidden;
}

.progress {
  height: 100%;
  background-color: #4CAF50;
  transition: width 0.3s ease-in-out;
}

.form-select {
  width: 100%;
  padding: 0.5em;
  margin-bottom: 1em;
}

.form-input {
  width: 100%;
  padding: 0.5em;
}
