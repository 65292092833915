/* Posts */

ul.posts {
    list-style: none;
    padding: 0;
}

    ul.posts li {
        border-top: dotted 1px rgba(160, 160, 160, 0.3);
        margin: 1.5em 0 0 0;
        padding: 1.5em 0 0 0;
    }

        ul.posts li:first-child {
            border-top: 0;
            margin-top: 0;
            padding-top: 0;
        }

    ul.posts article {
        display: -moz-flex;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        -moz-align-items: -moz-flex-start;
        -webkit-align-items: -webkit-flex-start;
        -ms-align-items: -ms-flex-start;
        align-items: flex-start;
        -moz-flex-direction: row-reverse;
        -webkit-flex-direction: row-reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
    }

        ul.posts article .image {
            display: block;
            margin-right: 1.5em;
            min-width: 4em;
            width: 4em;
        }

            ul.posts article .image img {
                width: 100%;
            }

        ul.posts article header {
            -moz-flex-grow: 1;
            -webkit-flex-grow: 1;
            -ms-flex-grow: 1;
            flex-grow: 1;
            -ms-flex: 1;
        }

            ul.posts article header h3 {
                font-size: 0.7em;
                margin-top: 0.125em;
                color: #6A4033;
            }

            ul.posts article header a:hover {
                border-bottom-color: transparent;
                color: #ffffff !important;
            }

                ul.posts article header a:hover:before {
                    color: #ffffff !important;
                }            

            ul.posts article header .published {
                display: block;
                font-family: "Raleway", Helvetica, sans-serif;
                font-size: 0.6em;
                font-weight: 400;
                letter-spacing: 0.25em;
                margin: -0.625em 0 1.7em 0;
                text-transform: uppercase;
            }

            ul.posts article header > :last-child {
                margin-bottom: 0;
            }    